<template>
  <div class="page-title">教科書の設定</div>
  <div class="text-book-btns">
    <div
      v-for="textbook in textbooks"
      :key="textbook.id"
      :class="{selected: isSelected(textbook)}"
      @click="selectTextbook(textbook)"
      class="text-book-btn">
      <span class="name" v-if="textbook.name">{{textbook.name}}</span>
      <span class="english-name"
        :class='[textbook.name ? "align-left": "align-center"]'
        >{{textbook.englishName}}</span>
    </div>
  </div>
  <div v-if="inProgress" class="btns-in-row mt-2">
    <button
      @click="back"
      class="btn-img">
      <img src="@/assets/icon-back.png"/>
    </button>
    <button
      @click="submit"
      :disabled="!selectedTextbookId"
      class="btn-bg">Finish</button>
  </div>
  <div v-else>
    <button
      @click="submit"
      :disabled="!selectedTextbookId"
      class="btn-bg center mt-2">Finish</button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SelectTextbook',
  props: {
    inProgress: {
      type: Boolean,
      require: true,
    }
  },
  computed: {
    ...mapState(['configs']),
    isSelected() {
      return textbook => textbook.id === this.selectedTextbookId
    }
  },
  data() {
    return {
      textbooks: [
        {id: 1, name: '東京書籍', englishName: 'NEW HORIZON'},
        {id: 2, name: '三省堂', englishName: 'NEW CROWN'},
        {id: 3, name: '開隆堂出版', englishName: 'SUNSHINE'},
        {id: 4, name: '教育出版', englishName: 'ONE WORLD'},
        {id: 5, name: '光村図書出版', englishName: 'Here We Go!'},
        {id: 6, name: '啓林館', englishName: 'BLUE SKY'},
        {id: 7, name: null, englishName: 'その他'},
      ],
      selectedTextbookId: null,
    }
  },
  created() {
    this.selectedTextbookId = this.configs.textbook
  },
  methods: {
    selectTextbook(textbook) {
      this.selectedTextbookId = textbook.id
    },
    submit() {
      this.$emit('submit', this.selectedTextbookId)
    },
    back() {
      this.$emit('back')
    }
  }
}
</script>

<style lang="scss" scoped>
.text-book-btns {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: center;

  .text-book-btn:hover {
    border: 2px rgb(29, 200, 29) solid;
  }

  .text-book-btn {
    color: white;
    background: linear-gradient(to bottom, #28529D, #0E1D3A);
    box-shadow: 3px 3px 6px 1px rgb(80 80 80 / 30%);
    padding: 8px 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    width: 356px;
    font-size: 25px;
    font-weight: bold;
    margin: 20px 4%;
    border: 2px #28529D solid;
    .english-name {
      margin-left: 10px;
      flex: 3;
    }
    .name {
      background: white;
      color: #0E1D3A;
      flex: 2;
      font-size: 18px;
      border-radius: 10px;
      padding: 2px;
    }
  }
 .selected {
    cursor: pointer;
    background: linear-gradient(to bottom, #789ee0, #789ee0);
    border: 2px solid #789ee0;
  }
  .text-book-btn:hover {
    border: 2px rgb(29, 200, 29) solid;
    background: linear-gradient(to bottom, #789ee0, #5773a8);
  }
}
.btn-bg {
  font-size: 23px;
  padding: 15px;
  width: 180px;
}
</style>

