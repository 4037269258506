<template>
  <div class="header">
    <div
      @click="backToConfig()"
      class="btn-img">
      <img src="@/assets/icon-back.png"/>
    </div>
  </div>
  <select-textbook
    @submit="submit"
    :inProgress="false"/>
</template>

<script>
import SelectTextbook from '../../components/molecule/SelectTextbook.vue'

export default {
  name: 'ConfigTextbook',
  components: {SelectTextbook, },
  data() {
    return {
    }
  },
  methods: {
    selectSchool(school) {
      this.selectedSchool = school
    },
    backToConfig() {
      this.$router.push('/config')
    },
    async submit(textbook) {
      this.$store.commit('setConfigTextbook', textbook)
      await this.$store.commit('saveConfig')
      this.$router.push('/config')
    },
  }
}
</script>

